import { FC, useState } from 'react';
import xlsx from 'xlsx';
import { useRequest } from '@umijs/hooks';
import { useIntl } from 'umi';
import { Button, Input, AttachedSelect as Select } from 'iglooform';
import { Upload, Space, Table } from 'antd';
import {
  CloseCircleFilled,
  CheckCircleFilled,
  LoadingOutlined,
} from '@ant-design/icons';
import { updateAccount, createAccount } from '../../service';
import { s2ab, openDownloadDialog } from '@/utils/download';

import styles from './index.less';

interface Props {
  platforms: Auth.Platform[];
  accounts: Auth.Account[];
}

const Import: FC<Props> = ({ platforms, accounts: accountsProp }) => {
  const [accounts, setAccounts] = useState<Auth.Account[]>([]);
  const {
    fetches: updateFetches,
    run: update,
    loading: updateLoading,
  } = useRequest(updateAccount, {
    manual: true,
    fetchKey: (...params) => params[0].email,
  });
  const {
    fetches: createFetches,
    run: create,
    loading: createLoading,
  } = useRequest(createAccount, {
    manual: true,
    fetchKey: (...params) => params[0].email,
  });

  const { formatMessage } = useIntl();

  const parseFile = (file: File) => {
    try {
      const reader = new FileReader();

      reader.onload = (e) => {
        const result = e.target?.result;
        const workbook = xlsx.read(result, { type: 'binary' });

        let data: {
          email: string;
          platformKeyStr: string;
          password: string;
        }[] = [];

        for (let sheet in workbook.Sheets) {
          data = xlsx.utils.sheet_to_json(workbook.Sheets[sheet], {
            header: ['email', 'password', 'platformKeyStr'],
            range: 1,
          });

          break;
        }

        const output: Auth.Account[] = [];

        data.forEach(({ email, password, platformKeyStr }) => {
          const platformKeys = platformKeyStr ? platformKeyStr.split(',') : [];

          const account = accountsProp.find(
            ({ email: emailProp }) => email === emailProp,
          );

          output.push({
            email: account?.email || email,
            platforms: Array.from(
              new Set([
                ...platformKeys.map((key) => key.trim()),
                ...(account?.platforms || []).map(({ key }: any) => key),
              ]),
            ),
            password,
          });
        });

        setAccounts(output);
      };

      reader.readAsBinaryString(file);
    } catch (e) {
      console.log(e);
    }
  };

  const handleUplaod = () => {
    accounts.forEach((account) => {
      account.uid ? update(account) : create(account);
    });
  };

  const handleDownload = () => {
    const workbook = xlsx.utils.book_new();
    const sheet = xlsx.utils.aoa_to_sheet([
      ['email', 'password', 'platformKeys'],
      ...accountsProp.map(({ email }) => [email, '']),
    ]);
    xlsx.utils.book_append_sheet(workbook, sheet);

    const wopts = {
      bookType: 'xlsx', // 要生成的文件类型
      bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
      type: 'binary',
    } as any;
    const wbout = xlsx.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    openDownloadDialog(blob, 'template.xlsx');
  };

  return (
    <div className={styles.container}>
      <Space className={styles.buttons} size={16}>
        <Upload
          beforeUpload={(file) => {
            parseFile(file);
            return false;
          }}
          showUploadList={false}
        >
          <Button className={styles.button}>
            {formatMessage({
              id: 'Select File',
              defaultMessage: 'Select File',
            })}
          </Button>
        </Upload>
        <Button
          onClick={handleUplaod}
          className={styles.button}
          disabled={!accounts.length || updateLoading || createLoading}
        >
          {formatMessage({
            id: 'Upload',
            defaultMessage: 'Upload',
          })}
        </Button>
        <Button onClick={handleDownload} className={styles.button}>
          {formatMessage({
            id: 'Download Template',
            defaultMessage: 'Download Template',
          })}
        </Button>
      </Space>
      <div className={styles.content}>
        <Table
          dataSource={accounts}
          pagination={false}
          columns={[
            {
              title: formatMessage({ id: 'Status', defaultMessage: 'Status' }),
              dataIndex: 'key',
              render: (value, record, index) => {
                const status =
                  updateFetches[record.email] || createFetches[record.email];

                if (status?.loading) {
                  return <LoadingOutlined />;
                }

                if (status?.error) {
                  return <CloseCircleFilled style={{ color: '#F52209' }} />;
                }

                if (status && !status.error) {
                  return <CheckCircleFilled style={{ color: '#108043' }} />;
                }

                return '-';
              },
            },
            {
              title: formatMessage({ id: 'Email', defaultMessage: 'Email' }),
              dataIndex: 'email',
              render: (value, record, index) => {
                const status =
                  updateFetches[record.email] || createFetches[record.email];
                const error = status?.error as any;

                return (
                  <>
                    <Input value={value} disabled />
                    {error && (
                      <div style={{ color: '#F52209', marginTop: 8 }}>
                        {error?.data?.message}
                      </div>
                    )}
                  </>
                );
              },
            },
            {
              title: formatMessage({
                id: 'Password',
                defaultMessage: 'Password',
              }),
              dataIndex: 'password',
              render: (value, record, index) => {
                return (
                  <Input
                    value={value}
                    onChange={(e) => {
                      const newArr = Array.from(accounts);

                      newArr[index] = {
                        ...record,
                        password: e.target.value,
                      };

                      setAccounts(newArr);
                    }}
                  />
                );
              },
            },
            {
              title: formatMessage({
                id: 'Platform',
                defaultMessage: 'Platform',
              }),
              dataIndex: 'platforms',
              render: (value, record, index) => {
                return (
                  <Select
                    className={styles.input}
                    value={value}
                    multiple
                    onChange={(platforms: any) => {
                      const newArr = Array.from(accounts);

                      newArr[index] = {
                        ...record,
                        platforms,
                      };

                      setAccounts(newArr);
                    }}
                    showSearch
                  >
                    {platforms.map(({ key, name }) => (
                      <Select.Option key={key} value={key}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                );
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Import;
