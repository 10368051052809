import { FC, useEffect, useState } from 'react';
import { Select, Input, Button } from 'iglooform';
import { useIntl } from 'umi';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import styles from './index.less';

const Methods = ['GET', 'POST', 'PUT', 'DELETE'];

interface Props {
  value?: Auth.API[];
  onChange: (apis: Auth.API[]) => void;
}

const APIList: FC<Props> = ({ value = [], onChange }) => {
  const [apis, setApis] = useState<Auth.API[]>(value);
  const [errors, setErrors] = useState<Boolean[]>([]);
  const { formatMessage } = useIntl();

  useEffect(() => {
    let hasErr = false;
    const errs = apis.map(({ path }) => {
      if (!path) {
        hasErr = true;
        return true;
      }

      return false;
    });

    if (!hasErr) {
      onChange(apis);
    }

    setErrors(errs);
  }, [apis]);

  const handleChange = (value: Auth.API, index: number) => {
    const newApis = Array.from(apis);

    newApis[index] = value;

    setApis(newApis);
  };

  const handleDelete = (index: number) => {
    const newApis = Array.from(apis);

    newApis.splice(index, 1);

    setApis(newApis);
  };

  const handleAdd = () => {
    const newApis = Array.from(apis);

    newApis.push({ method: 'GET', path: '' });

    setApis(newApis);
  };

  return (
    <div className={styles.apiList}>
      {apis.map((api, index) => (
        <API
          {...api}
          onChange={(api) => handleChange(api, index)}
          onDelete={() => handleDelete(index)}
          key={index}
          hasError={Boolean(errors[index])}
        />
      ))}
      <Button
        icon={<PlusOutlined />}
        className={styles.add}
        onClick={handleAdd}
      >
        {formatMessage({
          id: 'Add API',
          defaultMessage: 'Add API',
        })}
      </Button>
    </div>
  );
};

export default APIList;

const API: FC<{
  method: Auth.Method;
  path: string;
  hasError: boolean;
  onChange: (value: Auth.API) => void;
  onDelete: () => void;
}> = ({ method: methodProp, path: pathProp, hasError, onChange, onDelete }) => {
  const [method, setMethod] = useState(methodProp || 'GET');
  const [path, setPath] = useState(pathProp);

  useEffect(() => {
    onChange({
      method,
      path,
    });
  }, [method, path]);

  return (
    <div className={styles.apiItem}>
      <Select
        className={styles.method}
        onChange={(value) => setMethod(value as Auth.Method)}
        value={method}
      >
        {Methods.map((key) => (
          <Select.Option key={key} value={key}>
            {key}
          </Select.Option>
        ))}
      </Select>
      <Input
        className={classnames({
          [styles.path]: true,
          [styles.hasError]: hasError,
        })}
        value={path}
        onChange={(e) => setPath(e.target.value)}
      />
      <Button
        className={styles.delete}
        icon={<DeleteOutlined />}
        onClick={onDelete}
      />
    </div>
  );
};
