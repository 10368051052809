import request from '@/utils/request';

export const getPermissions = (platform: string) => {
  return request(`/v1/admin_account/admin/platform/${platform}/permissions`);
};

export const createPermission = (platform: string, data: any) => {
  return request(`/v1/admin_account/admin/platform/${platform}/permissions`, {
    method: 'post',
    data,
  });
};

export const updatePermission = (id: any, data: any) => {
  return request(`/v1/admin_account/admin/permissions/${id}`, {
    method: 'put',
    data,
  });
};

export const deletePermission = (id: any) => {
  return request(`/v1/admin_account/admin/permissions/${id}`, {
    method: 'delete',
  });
};
