import { extend, ResponseError } from 'umi-request';

const errorHandler = (error: ResponseError) => {
  const { response } = error;
  if (response && response.status) {
    const { status, url } = response;
    console.log(status, url);

    if (status === 401) {
      const lastToken = localStorage.getItem('token');
      localStorage.removeItem('token');
      window.location.href = `${SSO_LOGIN}/logout?app=${window.location.origin}&lastToken=${lastToken}`;
    }
  } else if (!response) {
    console.log('error');
  }

  throw error;
};

const request = extend({
  prefix: API_URL,
  headers: {
    'X-Axinan-Authorization': localStorage.getItem('token') as string,
  },
  errorHandler,
});

export const updateRequest = (token: string) => {
  request.extendOptions({
    prefix: API_URL,
    headers: {
      'X-Axinan-Authorization': token,
    },
    errorHandler,
  });
};

export default request;
