import { history, Redirect } from 'umi';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { tokenState, emailState } from '@/store/global';
import { updateRequest } from '@/utils/request';
import { parseJWT } from '@/utils/jwt';
import queryString from 'query-string';

const Login = () => {
  const [token, setToken] = useRecoilState(tokenState);
  const setEmail = useSetRecoilState(emailState);

  const handleLoginSuccess = (token: string) => {
    localStorage.setItem('token', token);
    setToken(token);
    updateRequest(token);

    const scopeData = parseJWT();
    setEmail(scopeData?.email);

    history.push('/platforms');
  };
  const params = queryString.parse(window.location.search);

  if (params.token) {
    handleLoginSuccess(params.token as string);
  }

  if (token || params.token) {
    return <Redirect to="/platforms" />;
  } else {
    window.location.href = `${SSO_LOGIN}/login?app=${window.location.href}`;

    return null;
  }
};

export default Login;
