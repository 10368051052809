import { FC, useState } from 'react';
import { Table, Space, message } from 'antd';
import { Button, Modal, Input } from 'iglooform';
import { useIntl, history } from 'umi';
import { useRequest } from '@umijs/hooks';
import { useRecoilValue } from 'recoil';
import { currentPlatformState } from '@/store/global';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  getPermissions,
  createPermission,
  updatePermission,
  deletePermission,
} from './service';
import APIList from './components/api-list';
import Import from './components/import';
import { s2ab, openDownloadDialog } from '@/utils/download';
import xlsx from 'xlsx';

import styles from './index.less';

const Permission: FC = () => {
  const [permissions, setPermissions] = useState<Auth.Permission[]>([]);
  const { formatMessage } = useIntl();
  const currentPlatform = useRecoilValue(currentPlatformState) as string;
  const [showModal, setShowModal] = useState(false);
  const [editPermission, setEditPermission] = useState<Auth.Permission>(
    {} as Auth.Permission,
  );
  const [showUpload, setShowUplaod] = useState(false);

  const { run: refresh, loading } = useRequest(getPermissions, {
    defaultParams: [currentPlatform],
    onSuccess: ({ permissions }) => {
      setPermissions(permissions);
    },
    cacheKey: `${currentPlatform}-permissions`,
  });

  const { run: create, loading: createLoading } = useRequest(createPermission, {
    manual: true,
    onSuccess: () => {
      refresh(currentPlatform);
      setShowModal(false);
    },
    onError: (err: any) => {
      message.error(err?.data?.message);
    },
  });

  const { run: update, loading: updateLoading } = useRequest(updatePermission, {
    manual: true,
    onSuccess: () => {
      refresh(currentPlatform);
      setShowModal(false);
    },
    onError: (err: any) => {
      message.error(err?.data?.message);
    },
  });

  const { run: del } = useRequest(deletePermission, {
    manual: true,
    onSuccess: () => {
      refresh(currentPlatform);
    },
    onError: (err: any) => {
      message.error(err?.data?.message);
    },
  });

  if (!currentPlatform) {
    history.push('/platforms');
    return null;
  }

  const openCreateModal = () => {
    setEditPermission({} as Auth.Permission);
    setShowModal(true);
  };

  const handleSave = () => {
    if (editPermission.id !== undefined) {
      update(editPermission.id, editPermission);
      return;
    }

    create(currentPlatform, editPermission);
  };

  const handleDelete = ({ id }: any) => {
    Modal.confirm({
      title: 'Please Confirm',
      content: 'Deleting permission will affect all related roles.',
      onOk: () => confirmDelete(id),
    });
  };

  const confirmDelete = (id: any) => {
    del(id);
  };

  const handleExport = () => {
    const workbook = xlsx.utils.book_new();
    const sheet = xlsx.utils.aoa_to_sheet([
      ['key', 'describe', 'api'],
      ...permissions.map(({ key, describe, apis = [] }) => {
        return [
          key,
          describe,
          apis.map(({ method, path }) => `${method} ${path}`).join('\r,'),
        ];
      }),
    ]);
    xlsx.utils.book_append_sheet(workbook, sheet);

    const wopts = {
      bookType: 'xlsx', // 要生成的文件类型
      bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
      type: 'binary',
    } as any;
    const wbout = xlsx.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    openDownloadDialog(blob, `${currentPlatform}-permissions.xlsx`);
  };

  return (
    <div className={styles.container}>
      <Space className={styles.buttons} size={16}>
        <Button type="default" onClick={openCreateModal}>
          {formatMessage({
            id: 'New Permission',
            defaultMessage: 'New Permission',
          })}
        </Button>
        <Button type="default" onClick={() => setShowUplaod(true)}>
          {formatMessage({
            id: 'Import Permission',
            defaultMessage: 'Import Permission',
          })}
        </Button>
        <Button type="default" onClick={handleExport}>
          {formatMessage({
            id: 'Export Permission',
            defaultMessage: 'Export Permission',
          })}
        </Button>
      </Space>
      <Table
        className={styles.table}
        loading={loading}
        dataSource={permissions}
        columns={[
          {
            title: formatMessage({
              id: 'Key/Name',
              defaultMessage: 'Key/Name',
            }),
            dataIndex: 'key',
          },
          {
            title: formatMessage({
              id: 'Description',
              defaultMessage: 'Description',
            }),
            dataIndex: 'describe',
          },
          {
            title: formatMessage({
              id: 'API List',
              defaultMessage: 'API List',
            }),
            dataIndex: 'apis',
            render: (apis) => {
              return apis?.map(({ method, path }: any, index: number) => (
                <div
                  style={{
                    display: 'flex',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  key={index}
                >
                  <div style={{ flex: '0 0 48px' }}>{method}</div>
                  <div style={{ flex: 'auto' }}>{path}</div>
                </div>
              ));
            },
            width: '40%',
          },
          {
            title: formatMessage({
              id: 'Operation',
              defaultMessage: 'Operation',
            }),
            width: '20%',
            render: (_, record) =>
              record?.key === 'admin' ? (
                '-'
              ) : (
                <>
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => {
                      setEditPermission(record);
                      setShowModal(true);
                    }}
                    type="link"
                  />
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => handleDelete(record)}
                    style={{ marginLeft: 16 }}
                    type="link"
                  />
                </>
              ),
          },
        ]}
        rowKey="key"
      />
      <Modal
        visible={showUpload}
        width="80vw"
        footer={null}
        title={formatMessage({
          id: 'Import Permission',
          defaultMessage: 'Import Permission',
        })}
        destroyOnClose
        onCancel={() => {
          refresh(currentPlatform);
          setShowUplaod(false);
        }}
        maskClosable={false}
        forceRender={false}
      >
        <Import />
      </Modal>
      <Modal
        className={styles.modal}
        width={600}
        forceRender={false}
        maskClosable={false}
        visible={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
        title={formatMessage({
          id: 'New Permission',
          defaultMessage: 'New Permission',
        })}
        destroyOnClose
      >
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({ id: 'Key', defaultMessage: 'Key' })}
          </div>
          <Input
            className={styles.input}
            value={editPermission?.key}
            onChange={(e) =>
              setEditPermission({
                ...editPermission,
                key: e.target.value,
              })
            }
          />
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({
              id: 'Description',
              defaultMessage: 'Description',
            })}
          </div>
          <Input
            className={styles.input}
            value={editPermission?.describe}
            onChange={(e) =>
              setEditPermission({
                ...editPermission,
                describe: e.target.value,
              })
            }
          />
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({
              id: 'API List',
              defaultMessage: 'API List',
            })}
          </div>
          <div className={styles.input}>
            <APIList
              value={editPermission.apis}
              onChange={(apis) =>
                setEditPermission({
                  ...editPermission,
                  apis,
                })
              }
            />
          </div>
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.saveButton}
            type="primary"
            onClick={handleSave}
            loading={createLoading || updateLoading}
            disabled={!editPermission.key}
          >
            {formatMessage({ id: 'Confirm', defaultMessage: 'Confirm' })}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Permission;
