import request from '@/utils/request';

export const getPlatforms = () => {
  return request('/v1/admin_account/admin/platforms');
};

export const createPlatform = async (data: any) => {
  await request('/v1/admin_account/admin/platforms', {
    method: 'post',
    data,
  });

  const [
    { id: permissionId },
    { id: roleId },
    { id: accountId },
    { id: emailId },
    { id: logosId },
  ] = await Promise.all([
    request(`/v1/admin_account/admin/platform/admin/permissions`, {
      method: 'post',
      data: {
        key: `${data.key}_permission`,
        description: `${data.key} permission`,
      },
    }),
    request(`/v1/admin_account/admin/platform/admin/permissions`, {
      method: 'post',
      data: {
        key: `${data.key}_role`,
        description: `${data.key} role`,
      },
    }),
    request(`/v1/admin_account/admin/platform/admin/permissions`, {
      method: 'post',
      data: {
        key: `${data.key}_account`,
        description: `${data.key} account`,
      },
    }),
    request(`/v1/admin_account/admin/platform/admin/permissions`, {
      method: 'post',
      data: {
        key: `${data.key}_email`,
        description: `${data.key} email template`,
      },
    }),
    request(`/v1/admin_account/admin/platform/admin/permissions`, {
      method: 'post',
      data: {
        key: `${data.key}_logos`,
        description: `${data.key} logos`,
      },
    }),
  ]);

  await request(`/v1/admin_account/admin/platform/admin/roles`, {
    method: 'post',
    data: {
      key: `${data.key}_admin`,
      name: `${data.key}_admin`,
      permissions: [permissionId, roleId, accountId, emailId, logosId],
    },
  });
};

export const updatePlatform = (data: any) => {
  return request(`/v1/admin_account/admin/platforms/${data.id}`, {
    method: 'put',
    data,
  });
};

export const deletePlatform = (key: string) => {
  return request(`/v1/admin_account/admin/platforms/${key}`, {
    method: 'delete',
  });
};
