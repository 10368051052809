import request from '@/utils/request';

export const getPermissions = (platform: string) => {
  return request(`/v1/admin_account/admin/platform/${platform}/permissions`);
};

export const getRoles = (platform: string) => {
  return request(`/v1/admin_account/admin/platform/${platform}/roles`);
};

export const createRole = (platform: string, data: Auth.EditRole) => {
  return request(`/v1/admin_account/admin/platform/${platform}/roles`, {
    method: 'post',
    data,
  });
};

export const updateRole = (id: any, data: Auth.EditRole) => {
  return request(`/v1/admin_account/admin/roles/${id}`, {
    method: 'put',
    data,
  });
};

export const deleteRole = (id: any) => {
  return request(`/v1/admin_account/admin/roles/${id}`, {
    method: 'delete',
  });
};
