import { FormattedMessage } from 'umi';

interface MenuItem {
  title: any;
  key: string;
  scopeKey: string;
}

const adminMenus: MenuItem[] = [
  {
    title: <FormattedMessage id="Platform" defaultMessage="Platform" />,
    key: '/admin-platform',
    scopeKey: 'admin',
  },
  {
    title: <FormattedMessage id="All Account" defaultMessage="All Account" />,
    key: '/admin-account',
    scopeKey: 'admin',
  },
  {
    title: <FormattedMessage id="Permission" defaultMessage="Permission" />,
    key: '/permission',
    scopeKey: 'admin',
  },
  {
    title: <FormattedMessage id="Role" defaultMessage="Role" />,
    key: '/role',
    scopeKey: 'admin',
  },
  {
    title: <FormattedMessage id="Account" defaultMessage="Account" />,
    key: '/account',
    scopeKey: 'admin',
  },
];

const platformMenus: MenuItem[] = [
  {
    title: (
      <FormattedMessage id="Email Template" defaultMessage="Email Template" />
    ),
    key: '/email-template',
    scopeKey: 'email',
  },
  {
    title: <FormattedMessage id="Logos" defaultMessage="Logos" />,
    key: '/logos',
    scopeKey: 'logos',
  },
  {
    title: <FormattedMessage id="Permission" defaultMessage="Permission" />,
    key: '/permission',
    scopeKey: 'permission',
  },
  {
    title: <FormattedMessage id="Role" defaultMessage="Role" />,
    key: '/role',
    scopeKey: 'role',
  },
  {
    title: <FormattedMessage id="Account" defaultMessage="Account" />,
    key: '/account',
    scopeKey: 'account',
  },
];

export const getMenus = (platform: string, scope: any = {}) => {
  const menus = [];
  if (platform === 'admin' && scope?.admin) {
    menus.push(...adminMenus);
  }

  if (platform !== 'admin') {
    platformMenus.forEach((menu) => {
      if (`${platform}_${menu.scopeKey}` in scope) {
        menus.push(menu);
      }
    });
  }

  return menus;
};
