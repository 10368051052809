import { FC, useState } from 'react';
import { Space, message, Table, Input as AntdInput, Row, Col } from 'antd';
import {
  Button,
  Modal,
  Input,
  AttachedSelect as Select,
  AdminUpload,
  FreeForm,
  Form,
  FormSection,
  FormItem,
} from 'iglooform';
import { useIntl, history } from 'umi';
import { useRequest } from '@umijs/hooks';
import { useRecoilValue } from 'recoil';
import { currentPlatformState } from '@/store/global';
import {
  getAccounts,
  updateAccountRole,
  getRoles,
  getEmailTemplates,
  updateAccount,
  getBackupCode,
  generateCodeByPhoneNumber,
} from './service';
import NewAccount from './components/new-account';
import loginMethod from '@/utils/login-method';
import request from '@/utils/request';
import upload from '@/utils/upload';
import xlsx from 'xlsx';
import { s2ab, openDownloadDialog } from '@/utils/download';
import { CopyOutlined } from 'iglooicon';

import styles from './index.less';

const KEEP_BACKUP_BUTTON_PLATFORMS = [
  'UICEB',
  'Lacson-Broker',
  'Lacson-HR',
  'Shopee-Malaysia',
  'Shopee-Deals',
  'Shopee-PH',
  'Shopee-HC',
  'Shopee-MY',
];

const AdminAccount: FC = () => {
  const [accounts, setAccounts] = useState<Auth.Account[]>([]);
  const [roles, setRoles] = useState<Auth.Role[]>([]);
  const [emailTemplates, setEmailTemplates] = useState<Auth.EmailTemplate[]>(
    [],
  );
  const { formatMessage } = useIntl();
  const currentPlatform = useRecoilValue(currentPlatformState) as string;
  const [showEdit, setShowEdit] = useState(false);
  const [showEditInfo, setShowEditInfo] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showGenerateCode, setShowGenerateCode] = useState(false);
  const [editAccount, setEditAccount] = useState<Auth.EditAccount>(
    {} as Auth.EditAccount,
  );
  const form = Form.useForm();
  const showBackUpButton =
    KEEP_BACKUP_BUTTON_PLATFORMS.includes(currentPlatform);

  const { run: refresh, loading } = useRequest(getAccounts, {
    defaultParams: [currentPlatform],
    onSuccess: ({ data }) => {
      setAccounts(data);
    },
  });

  useRequest(getRoles, {
    defaultParams: [currentPlatform],
    onSuccess: ({ roles }) => {
      setRoles(roles);
    },
    cacheKey: `${currentPlatform}-roles`,
  });

  useRequest(getEmailTemplates, {
    defaultParams: [currentPlatform],
    onSuccess: ({ templates }) => {
      setEmailTemplates(templates);
    },
    cacheKey: `${currentPlatform}-email-templates`,
  });

  const { run: update, loading: createLoading } = useRequest(
    updateAccountRole,
    {
      manual: true,
      onSuccess: () => {
        refresh(currentPlatform);
        setShowEdit(false);
      },
      onError: (err: any) => {
        message.error(err?.data?.message);
      },
    },
  );

  const { run: updateInfo, loading: updateLoading } = useRequest(
    updateAccount,
    {
      manual: true,
      onSuccess: () => {
        refresh(currentPlatform);
        setShowEditInfo(false);
      },
      onError: (err: any) => {
        message.error(err?.data?.message);
      },
    },
  );

  const { run: generateBackupCode } = useRequest(getBackupCode, {
    manual: true,
    onSuccess: (data) => {
      Modal.success({
        title: 'Backup Code',
        content: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {data.code}
            <Button
              icon={<CopyOutlined />}
              type="link"
              onClick={() => {
                navigator.clipboard.writeText(data.code);
                message.success('Copied to clipboard.');
              }}
            />
          </div>
        ),
      });
    },
  });

  const { run: generateCodeByPhone } = useRequest(generateCodeByPhoneNumber, {
    manual: true,
    onSuccess: (data) => {
      setShowGenerateCode(false);
      Modal.success({
        title: 'Backup Code',
        content: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {data.code}
            <Button
              icon={<CopyOutlined />}
              type="link"
              onClick={() => {
                navigator.clipboard.writeText(data.code);
                message.success('Copied to clipboard.');
              }}
            />
          </div>
        ),
      });
    },
  });

  if (!currentPlatform) {
    history.push('/platforms');
    return null;
  }

  const handleCreate = () => {
    update(currentPlatform, editAccount);
  };

  const handleUpdate = () => {
    updateInfo(editAccount);
  };

  const handleDownload = () => {
    const workbook = xlsx.utils.book_new();
    const sheet = xlsx.utils.aoa_to_sheet([
      [
        'Email',
        'Company',
        'roleKeys',
        'AreaCode',
        'PhoneNumber',
        'MailTemplate',
      ],
      ['aaa@aaa.com', 'Igloo', 'roleKey1,roleKey2', 86, 123456, ''],
    ]);
    xlsx.utils.book_append_sheet(workbook, sheet);

    const wopts = {
      bookType: 'xlsx',
      bookSST: false,
      type: 'binary',
    } as any;
    const wbout = xlsx.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    openDownloadDialog(blob, 'account-template.xlsx');
  };

  const handleGenerateCodeByPhoneNumber = async () => {
    try {
      await form.validateFields();
      const res = form.getFieldsValue();
      generateCodeByPhone(currentPlatform, res);
    } catch {
      return;
    }
  };

  return (
    <div className={styles.container}>
      <Space className={styles.buttons} size={16}>
        <Button type="default" onClick={() => setShowCreate(true)}>
          {formatMessage({
            id: 'New Account',
            defaultMessage: 'New Account',
          })}
        </Button>
        <AdminUpload
          requestMethod={request}
          uploadMethod={upload}
          onFinish={() => refresh(currentPlatform)}
          buttonText={formatMessage({
            id: 'Import Account',
            defaultMessage: 'Import Account',
          })}
          accept=".csv,.xlsx"
          dataSource="admin-account_CreateAccount"
        />
        <Button type="default" onClick={handleDownload}>
          {formatMessage({
            id: 'Download Template',
            defaultMessage: 'Download Template',
          })}
        </Button>
        {showBackUpButton && (
          <Button
            type="default"
            onClick={() => {
              form.resetFields();
              setShowGenerateCode(true);
            }}
          >
            {formatMessage({
              id: 'Generate Code By Phone',
              defaultMessage: 'Generate Code By Phone',
            })}
          </Button>
        )}
      </Space>
      <Table
        className={styles.table}
        loading={loading}
        dataSource={accounts}
        columns={[
          {
            title: formatMessage({ id: 'Email', defaultMessage: 'Email' }),
            dataIndex: 'email',
            filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) => {
              return (
                <Space direction="vertical" size={16} style={{ padding: 24 }}>
                  <Input
                    onChange={(e) => setSelectedKeys([e.target.value])}
                    value={selectedKeys[0]}
                  />
                  <Space size={16}>
                    <Button
                      onClick={() => {
                        setSelectedKeys([]);
                        confirm();
                      }}
                      type="text"
                      size="small"
                    >
                      Reset
                    </Button>
                    <Button onClick={() => confirm()} type="text" size="small">
                      Confirm
                    </Button>
                  </Space>
                </Space>
              );
            },
            onFilter: (value, record) =>
              Boolean(record.email?.includes(value as string)),
          },
          {
            title: formatMessage({
              id: 'Company',
              defaultMessage: 'Company',
            }),
            dataIndex: 'company_name',
            render: (v) => v || '-',
          },
          {
            title: formatMessage({
              id: 'Phone Number',
              defaultMessage: 'Phone Number',
            }),
            dataIndex: 'phone_number',
            render: (v, record) => {
              const { phone_number, phone_area_code } = record;

              return phone_area_code || phone_number
                ? `${phone_area_code || ''}${phone_number || ''}`
                : '-';
            },
            filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) => {
              return (
                <Space direction="vertical" size={16} style={{ padding: 24 }}>
                  <Input
                    onChange={(e) => setSelectedKeys([e.target.value])}
                    value={selectedKeys[0]}
                  />
                  <Space size={16}>
                    <Button
                      onClick={() => {
                        setSelectedKeys([]);
                        confirm();
                      }}
                      type="text"
                      size="small"
                    >
                      Reset
                    </Button>
                    <Button onClick={() => confirm()} type="text" size="small">
                      Confirm
                    </Button>
                  </Space>
                </Space>
              );
            },
            onFilter: (value, record) =>
              Boolean(record.phone_number?.includes(value as string)),
          },
          {
            title: formatMessage({
              id: 'Roles',
              defaultMessage: 'Roles',
            }),
            dataIndex: 'roles',
            render: (roles) => roles?.map(({ name }: any) => name)?.join(', '),
          },
          {
            title: formatMessage({
              id: 'Operation',
              defaultMessage: 'Operation',
            }),
            width: '30%',
            render: (_, record) => (
              <>
                <Button
                  onClick={() => {
                    setEditAccount({
                      ...record,
                      role_ids: record?.roles?.map(({ id }) => id as number),
                    });
                    setShowEdit(true);
                  }}
                  type="link"
                >
                  {formatMessage({
                    id: 'Update Roles',
                    defaultMessage: 'Update Roles',
                  })}
                </Button>
                <Button
                  onClick={() => {
                    setEditAccount({
                      ...record,
                      role_ids: record?.roles?.map(({ id }) => id as number),
                    });
                    setShowEditInfo(true);
                  }}
                  style={{ marginLeft: 16 }}
                  type="link"
                >
                  {formatMessage({
                    id: 'Update Profile',
                    defaultMessage: 'Update Profile',
                  })}
                </Button>
                {showBackUpButton && (
                  <Button
                    onClick={() => {
                      generateBackupCode(currentPlatform, record);
                    }}
                    style={{ marginLeft: 16 }}
                    type="link"
                  >
                    {formatMessage({
                      id: 'Generate Backup Code',
                      defaultMessage: 'Generate Backup Code',
                    })}
                  </Button>
                )}
              </>
            ),
          },
        ]}
        rowKey="email"
      />
      {showGenerateCode && (
        <Modal
          className={styles.modal}
          width={600}
          visible={showGenerateCode}
          footer={null}
          title={formatMessage({
            id: 'Generate Code',
            defaultMessage: 'Generate Code',
          })}
          destroyOnClose
          onCancel={() => {
            setShowGenerateCode(false);
          }}
          maskClosable={false}
          forceRender={false}
          limitHeight={false}
        >
          <FreeForm form={form}>
            <FormSection>
              <FormItem name="phone_area_code" label="Area Code">
                <Input></Input>
              </FormItem>
              <FormItem name="phone_number" label="Phone Number">
                <Input></Input>
              </FormItem>
            </FormSection>
            <Row justify="end" style={{ marginTop: 24 }}>
              <Button type="primary" onClick={handleGenerateCodeByPhoneNumber}>
                Next
              </Button>
            </Row>
          </FreeForm>
        </Modal>
      )}

      <Modal
        className={styles.modal}
        width={600}
        visible={showCreate}
        footer={null}
        title={formatMessage({
          id: 'New Account',
          defaultMessage: 'New Account',
        })}
        destroyOnClose
        onCancel={() => {
          refresh(currentPlatform);
          setShowCreate(false);
        }}
        maskClosable={false}
        forceRender={false}
        limitHeight={false}
      >
        <NewAccount
          onFinish={() => {
            refresh(currentPlatform);
            setShowCreate(false);
          }}
          roles={roles}
          platform={currentPlatform}
          accounts={accounts}
          emailTemplates={emailTemplates}
        />
      </Modal>
      <Modal
        className={styles.modal}
        width={600}
        forceRender={false}
        maskClosable={false}
        visible={showEdit}
        footer={null}
        onCancel={() => setShowEdit(false)}
        title={formatMessage({
          id: 'Edit Account',
          defaultMessage: 'Edit Account',
        })}
        destroyOnClose
        style={{ overflow: 'visible' }}
        limitHeight={false}
      >
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({ id: 'Email', defaultMessage: 'Email' })}
          </div>
          <Input
            className={styles.input}
            value={editAccount?.email}
            onChange={(e) =>
              setEditAccount({
                ...editAccount,
                email: e.target.value,
              })
            }
            disabled
          />
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({ id: 'Role', defaultMessage: 'Role' })}
          </div>
          <Select
            className={styles.input}
            value={editAccount?.role_ids}
            onChange={(data) =>
              setEditAccount({
                ...editAccount,
                role_ids: data as number[],
              })
            }
            multiple
            showSearch
          >
            {roles.map(({ id, name, key }) => (
              <Select.Option key={key} value={id as number}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.saveButton}
            type="primary"
            onClick={handleCreate}
            loading={createLoading}
          >
            {formatMessage({ id: 'Confirm', defaultMessage: 'Confirm' })}
          </Button>
        </div>
      </Modal>
      <Modal
        className={styles.modal}
        width={600}
        forceRender={false}
        maskClosable={false}
        visible={showEditInfo}
        footer={null}
        onCancel={() => setShowEditInfo(false)}
        title={formatMessage({
          id: 'Update Personal Informations',
          defaultMessage: 'Update Personal Informations',
        })}
        destroyOnClose
        limitHeight={false}
      >
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({ id: 'Email', defaultMessage: 'Email' })}
          </div>
          <Input
            className={styles.input}
            value={editAccount?.email}
            onChange={(e) =>
              setEditAccount({
                ...editAccount,
                email: e.target.value,
              })
            }
            disabled
          />
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({ id: 'Company', defaultMessage: 'Company' })}
          </div>
          <Input
            className={styles.input}
            value={editAccount?.company_name}
            onChange={(e) =>
              setEditAccount({
                ...editAccount,
                company_name: e.target.value,
              })
            }
          />
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({
              id: 'Phone Number',
              defaultMessage: 'Phone Number',
            })}
          </div>

          <AntdInput.Group compact className={styles.input}>
            <AntdInput
              style={{ width: '20%' }}
              onChange={(e) =>
                setEditAccount({
                  ...editAccount,
                  phone_area_code: e.target.value,
                })
              }
              value={editAccount.phone_area_code}
            />
            <AntdInput
              style={{ width: '80%' }}
              onChange={(e) =>
                setEditAccount({
                  ...editAccount,
                  phone_number: e.target.value,
                })
              }
              value={editAccount.phone_number}
            />
          </AntdInput.Group>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({
              id: 'Login Method',
              defaultMessage: 'Login Method',
            })}
          </div>
          <Select
            className={styles.input}
            value={loginMethod.filter(
              (method) =>
                !(editAccount?.disabled_login_mehtods || []).includes(method),
            )}
            onChange={(data) =>
              setEditAccount({
                ...editAccount,
                disabled_login_mehtods: loginMethod.filter(
                  (method) => !((data as string[]) || []).includes(method),
                ),
              })
            }
            multiple
          >
            <Select.Option key="password" value="password">
              {formatMessage({ id: 'Password', defaultMessage: 'Password' })}
            </Select.Option>
            <Select.Option key="google_oauth" value="google_oauth">
              {formatMessage({
                id: 'Google OAuth',
                defaultMessage: 'Google OAuth',
              })}
            </Select.Option>
            <Select.Option key="otp" value="otp">
              {formatMessage({ id: 'Otp', defaultMessage: 'Otp' })}
            </Select.Option>
          </Select>
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.saveButton}
            type="primary"
            onClick={handleUpdate}
            loading={updateLoading}
          >
            {formatMessage({ id: 'Confirm', defaultMessage: 'Confirm' })}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AdminAccount;
