import AWS from 'aws-sdk';
import { useRecoilValue } from 'recoil';
import { currentPlatformState } from '@/store/global';
import { useEffect, useState } from 'react';
import { Typography } from 'iglooform';
import { Upload, Divider, message, Space } from 'antd';
import { UploadOutlined } from 'iglooicon';

const region = 'ap-southeast-1';
const bucketName = 'iglooinsure.igloo-static';

AWS.config.update({
  region,
  credentials: {
    accessKeyId: 'AKIAU2NHV2XFADTKQNVK',
    secretAccessKey: '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',
  },
});

const s3 = new AWS.S3({ params: { Bucket: bucketName } });

export default () => {
  const currentPlatform = useRecoilValue(currentPlatformState) as string;
  const [doneCount, setDoneCount] = useState(0);
  const [normal, setNormal] = useState<string>();
  const [nav, setNav] = useState<string>();
  const [square, setSquare] = useState<string>();

  useEffect(() => {
    const normalImg = new Image();
    const navImg = new Image();
    const squareImg = new Image();

    normalImg.src = `https://static.iglooinsure.com/partner/normal/${currentPlatform.toLowerCase()}.svg`;
    navImg.src = `https://static.iglooinsure.com/partner/nav/${currentPlatform.toLowerCase()}.svg`;
    squareImg.src = `https://static.iglooinsure.com/partner/square/${currentPlatform.toLowerCase()}.svg`;

    s3.headObject(
      {
        Bucket: bucketName,
        Key: `partner/normal/${currentPlatform.toLowerCase()}.svg`,
      },
      (err, data) => {
        if (!err) {
          setNormal(
            `https://static.iglooinsure.com/partner/normal/${currentPlatform.toLowerCase()}.svg`,
          );
        }

        setDoneCount((c) => c + 1);
      },
    );
    s3.headObject(
      {
        Bucket: bucketName,
        Key: `partner/nav/${currentPlatform.toLowerCase()}.svg`,
      },
      (err, data) => {
        if (!err) {
          setNav(
            `https://static.iglooinsure.com/partner/nav/${currentPlatform.toLowerCase()}.svg`,
          );
        }

        setDoneCount((c) => c + 1);
      },
    );
    s3.headObject(
      {
        Bucket: bucketName,
        Key: `partner/square/${currentPlatform.toLowerCase()}.svg`,
      },
      (err, data) => {
        if (!err) {
          setSquare(
            `https://static.iglooinsure.com/partner/square/${currentPlatform.toLowerCase()}.svg`,
          );
        }

        setDoneCount((c) => c + 1);
      },
    );
  }, []);

  if (doneCount < 3) {
    return null;
  }

  const handleUpload = (file: File, type: string) => {
    s3.putObject(
      {
        Bucket: bucketName,
        Key: `partner/${type}/${currentPlatform.toLowerCase()}.svg`,
        Body: file,
        ContentType: file.type,
      },
      (err) => {
        if (err) {
          message.error('Upload logo failed, please try again later.');
          return;
        }

        if (type === 'normal') {
          setNormal(
            `https://static.iglooinsure.com/partner/normal/${currentPlatform.toLowerCase()}.svg`,
          );
        }

        if (type === 'nav') {
          setNav(
            `https://static.iglooinsure.com/partner/nav/${currentPlatform.toLowerCase()}.svg`,
          );
        }

        if (type === 'square') {
          setSquare(
            `https://static.iglooinsure.com/partner/square/${currentPlatform.toLowerCase()}.svg`,
          );
        }
      },
    );

    return Promise.reject();
  };

  return (
    <div style={{ padding: 32 }}>
      <Typography level="h1" wrapElement="div" style={{ marginBottom: 16 }}>
        Main Logo
      </Typography>
      <Space size={24}>
        <Upload
          beforeUpload={(file) => handleUpload(file, 'normal')}
          accept=".svg"
          showUploadList={false}
        >
          <div
            style={{
              border: '1px dashed',
              width: 96,
              height: 96,
              borderRadius: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            <UploadOutlined style={{ fontSize: 24, color: '#666666' }} />
            <span style={{ marginTop: 6 }}>
              <Typography level="body1">Upload</Typography>
            </span>
          </div>
        </Upload>
        <img src={normal} />
      </Space>
      <Divider />
      <Typography level="h1" wrapElement="div" style={{ marginBottom: 16 }}>
        Nav Logo
      </Typography>
      <Space size={24}>
        <Upload
          beforeUpload={(file) => handleUpload(file, 'nav')}
          accept=".svg"
          showUploadList={false}
        >
          <div
            style={{
              border: '1px dashed',
              width: 96,
              height: 96,
              borderRadius: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            <UploadOutlined style={{ fontSize: 24, color: '#666666' }} />
            <span style={{ marginTop: 6 }}>
              <Typography level="body1">Upload</Typography>
            </span>
          </div>
        </Upload>
        <img src={nav} />
      </Space>
      <Divider />
      <Typography level="h1" wrapElement="div" style={{ marginBottom: 16 }}>
        Cube Logo
      </Typography>
      <Space size={24}>
        <Upload
          beforeUpload={(file) => handleUpload(file, 'square')}
          accept=".svg"
          showUploadList={false}
        >
          <div
            style={{
              border: '1px dashed',
              width: 96,
              height: 96,
              borderRadius: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            <UploadOutlined style={{ fontSize: 24, color: '#666666' }} />
            <span style={{ marginTop: 6 }}>
              <Typography level="body1">Upload</Typography>
            </span>
          </div>
        </Upload>
        <img src={square} />
      </Space>
      <Divider />
    </div>
  );
};
