import { atom } from 'recoil';
import { parseJWT } from '@/utils/jwt';

const scopeData = parseJWT();

export const tokenState = atom({
  key: 'token',
  default: localStorage.getItem('token'),
});

export const emailState = atom({
  key: 'email',
  default: scopeData?.email,
});

export const platformsState = atom<Auth.Platform[]>({
  key: 'platforms',
  default: [],
});

export const currentPlatformState = atom<string | null>({
  key: 'currentPlatform',
  default: sessionStorage.getItem('currentPlatform'),
});

export const scopeState = atom<{
  [key: string]: boolean;
}>({
  key: 'scope',
  default: {
    loading: true,
  },
});
