import { FC, useState } from 'react';
import xlsx from 'xlsx';
import { useRequest } from '@umijs/hooks';
import { useIntl } from 'umi';
import { Button, Input, AttachedSelect as Select } from 'iglooform';
import { Table, Upload, Space } from 'antd';
import {
  CloseCircleFilled,
  CheckCircleFilled,
  LoadingOutlined,
} from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import { createRole } from '../../service';
import { currentPlatformState } from '@/store/global';
import { s2ab, openDownloadDialog } from '@/utils/download';

import styles from './index.less';

interface Props {
  permissions: Auth.Permission[];
}

const Import: FC<Props> = ({ permissions }) => {
  const [roles, setRoles] = useState<Auth.EditRole[]>([]);
  const platform = useRecoilValue(currentPlatformState) as string;
  const { fetches, run: create, loading } = useRequest(createRole, {
    manual: true,
    fetchKey: (...params) => params[1].key,
  });

  const { formatMessage } = useIntl();

  const parseFile = (file: File) => {
    try {
      const reader = new FileReader();

      reader.onload = (e) => {
        const result = e.target?.result;
        const workbook = xlsx.read(result, { type: 'binary' });

        let data: {
          key: string;
          permissionStr: string;
        }[] = [];

        for (let sheet in workbook.Sheets) {
          data = xlsx.utils.sheet_to_json(workbook.Sheets[sheet], {
            header: ['key', 'permissionStr'],
            range: 1,
          });

          break;
        }

        const output = data.map(({ key, permissionStr }) => {
          const permissionKeys = permissionStr ? permissionStr.split(',') : [];

          const permissionIds: number[] = [];

          permissionKeys.forEach((permissionKey) => {
            const permission = permissions.find(
              ({ key }) => permissionKey?.trim() === key,
            );

            if (permission) {
              permissionIds.push(permission.id as number);
            }
          });

          return {
            key: key?.trim(),
            name: key?.trim(),
            platform,
            permissions: permissionIds,
          };
        });

        setRoles(output);
      };

      reader.readAsBinaryString(file);
    } catch (e) {
      console.log(e);
    }
  };

  const handleUplaod = () => {
    roles.forEach((role) => {
      create(platform, role);
    });
  };

  const handleDownload = () => {
    const workbook = xlsx.utils.book_new();
    const sheet = xlsx.utils.aoa_to_sheet([
      ['key', 'permissionKeys'],
      ['aaa', 'aaaa,bbbb'],
    ]);
    xlsx.utils.book_append_sheet(workbook, sheet);

    const wopts = {
      bookType: 'xlsx', // 要生成的文件类型
      bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
      type: 'binary',
    } as any;
    const wbout = xlsx.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    openDownloadDialog(blob, 'template.xlsx');
  };

  return (
    <div className={styles.container}>
      <Space className={styles.buttons} size={16}>
        <Upload
          beforeUpload={(file) => {
            parseFile(file);
            return false;
          }}
          showUploadList={false}
        >
          <Button className={styles.button}>
            {formatMessage({
              id: 'Select File',
              defaultMessage: 'Select File',
            })}
          </Button>
        </Upload>
        <Button onClick={handleDownload} className={styles.button}>
          {formatMessage({
            id: 'Download Template',
            defaultMessage: 'Download Template',
          })}
        </Button>
        <Button
          type="primary"
          onClick={handleUplaod}
          className={styles.button}
          disabled={!permissions.length || loading}
        >
          {formatMessage({
            id: 'Upload',
            defaultMessage: 'Upload',
          })}
        </Button>
      </Space>
      <div className={styles.content}>
        <Table
          dataSource={roles}
          pagination={false}
          columns={[
            {
              title: formatMessage({ id: 'Status', defaultMessage: 'Status' }),
              dataIndex: 'key',
              render: (value, record, index) => {
                const status = fetches[record.key];

                if (status?.loading) {
                  return <LoadingOutlined />;
                }

                if (status?.error) {
                  return <CloseCircleFilled style={{ color: '#F52209' }} />;
                }

                if (status && !status.error) {
                  return <CheckCircleFilled style={{ color: '#108043' }} />;
                }

                return '-';
              },
            },
            {
              title: formatMessage({ id: 'Key', defaultMessage: 'Key' }),
              dataIndex: 'key',
              render: (value, record, index) => {
                const status = fetches[record.key];
                const error = status?.error as any;

                return (
                  <>
                    <Input
                      value={value}
                      onChange={(e) => {
                        const newArr = Array.from(roles);

                        newArr[index] = {
                          ...record,
                          key: e.target.value,
                        };

                        setRoles(newArr);
                      }}
                    />
                    {error && (
                      <div style={{ color: '#F52209', marginTop: 8 }}>
                        {error?.data?.message}
                      </div>
                    )}
                  </>
                );
              },
            },
            {
              title: formatMessage({
                id: 'Permissions',
                defaultMessage: 'Permissions',
              }),
              dataIndex: 'permissions',
              render: (value, record, index) => {
                return (
                  <Select
                    className={styles.input}
                    value={value}
                    multiple
                    onChange={(permissions: any) => {
                      const newArr = Array.from(roles);

                      newArr[index] = {
                        ...record,
                        permissions,
                      };

                      setRoles(newArr);
                    }}
                    showSearch
                  >
                    {permissions.map(({ key, id }) => (
                      <Select.Option key={key} value={id as number}>
                        {key}
                      </Select.Option>
                    ))}
                  </Select>
                );
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Import;
