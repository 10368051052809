import { FC, useState } from 'react';
import { Table, Space, message } from 'antd';
import { Button, Modal, Input } from 'iglooform';
import { useIntl, history } from 'umi';
import { useRequest } from '@umijs/hooks';
import { useRecoilValue } from 'recoil';
import { currentPlatformState } from '@/store/global';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { getEmailTemplates, updateEmailTemplates } from './service';

import styles from './index.less';

const EmailTemplate: FC = () => {
  const [emailTemplates, setEmailTemplates] = useState<Auth.EmailTemplate[]>(
    [],
  );
  const { formatMessage } = useIntl();
  const currentPlatform = useRecoilValue(currentPlatformState) as string;
  const [showModal, setShowModal] = useState(false);
  const [editEmailTemplate, setEditEmailTemplate] =
    useState<Auth.EmailTemplate>();

  const { run: refresh, loading } = useRequest(getEmailTemplates, {
    defaultParams: [currentPlatform],
    onSuccess: ({ templates }) => {
      setEmailTemplates(templates);
    },
    cacheKey: `${currentPlatform}-emailTemplates`,
  });

  const { run: update, loading: updateLoading } = useRequest(
    updateEmailTemplates,
    {
      manual: true,
      onSuccess: () => {
        refresh(currentPlatform);
        setShowModal(false);
      },
      onError: (err: any) => {
        message.error(err?.data?.message);
      },
    },
  );

  if (!currentPlatform) {
    history.push('/platforms');
    return null;
  }

  const openCreateModal = () => {
    setEditEmailTemplate('' as Auth.EmailTemplate);
    setShowModal(true);
  };

  const handleSave = () => {
    update(currentPlatform, [...emailTemplates, editEmailTemplate as string]);
  };

  const handleDelete = (index: any) => {
    Modal.confirm({
      title: 'Please Confirm',
      content: 'Are you sure to remove this email template.',
      onOk: () => confirmDelete(index),
    });
  };

  const confirmDelete = (index: any) => {
    const newList = emailTemplates.filter((_, i) => i !== index);
    update(currentPlatform, newList);
  };

  return (
    <div className={styles.container}>
      <Space className={styles.buttons} size={16}>
        <Button type="default" onClick={openCreateModal}>
          {formatMessage({
            id: 'New Email Template',
            defaultMessage: 'New Email Template',
          })}
        </Button>
      </Space>
      <Table
        className={styles.table}
        loading={loading}
        dataSource={emailTemplates.map((key) => ({ key }))}
        columns={[
          {
            title: formatMessage({
              id: 'Template Key',
              defaultMessage: 'Template Key',
            }),
            dataIndex: 'key',
          },
          {
            title: formatMessage({
              id: 'Operation',
              defaultMessage: 'Operation',
            }),
            width: '20%',
            render: (_, record, index) => (
              <Button
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(index)}
                style={{ marginLeft: 16 }}
                type="link"
              />
            ),
          },
        ]}
        rowKey="key"
      />
      <Modal
        className={styles.modal}
        width={600}
        forceRender={false}
        maskClosable={false}
        visible={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
        title={formatMessage({
          id: 'New EmailTemplate',
          defaultMessage: 'New EmailTemplate',
        })}
        destroyOnClose
      >
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({ id: 'Key', defaultMessage: 'Key' })}
          </div>
          <Input
            className={styles.input}
            value={editEmailTemplate}
            onChange={(e) => setEditEmailTemplate(e.target.value)}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.saveButton}
            type="primary"
            onClick={handleSave}
            loading={updateLoading}
            disabled={!editEmailTemplate}
          >
            {formatMessage({ id: 'Confirm', defaultMessage: 'Confirm' })}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default EmailTemplate;
