import { FC } from 'react';
import { Space } from 'antd';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { platformsState, currentPlatformState } from '@/store/global';
import { useIntl, history } from 'umi';
import { PlatformCard } from 'iglooform';
import styles from './index.less';

const SelectPlatform: FC = () => {
  const platforms = useRecoilValue(platformsState);
  const setPlatform = useSetRecoilState(currentPlatformState);

  const { formatMessage } = useIntl();

  const enterPlatform = async (key: string) => {
    sessionStorage.setItem('currentPlatform', key);
    await setPlatform(key);
    history.push('/account');
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {formatMessage({
          id: 'Please Select Platform',
          defaultMessage: 'Please Select Platform',
        })}
      </div>
      <Space className={styles.platforms} size={[40, 40]} wrap>
        {platforms.map(({ key, kind, name }) => {
          let badgeText;
          if (typeof kind === 'string' && kind !== 'admin_v3') {
            badgeText = kind.toUpperCase();
          }
          return (
            <PlatformCard
              key={key}
              platform={{ key, name }}
              onClick={() => enterPlatform(key)}
              badgeText={badgeText}
            />
          );
        })}
      </Space>
    </div>
  );
};

export default SelectPlatform;
