import { FC, useState } from 'react';
import xlsx from 'xlsx';
import { useRequest } from '@umijs/hooks';
import { useIntl } from 'umi';
import { Button, Input } from 'iglooform';
import { Table, Upload, Space } from 'antd';
import {
  CloseCircleFilled,
  CheckCircleFilled,
  LoadingOutlined,
} from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import APIList from '../api-list';
import { createPermission } from '../../service';
import { currentPlatformState } from '@/store/global';
import { s2ab, openDownloadDialog } from '@/utils/download';

import styles from './index.less';

const Import: FC = () => {
  const [permissions, setPermissions] = useState<Auth.Permission[]>([]);
  const platform = useRecoilValue(currentPlatformState) as string;
  const { fetches, run: create, loading } = useRequest(createPermission, {
    manual: true,
    fetchKey: (...params) => params[1].key,
  });

  const { formatMessage } = useIntl();

  const parseFile = (file: File) => {
    try {
      const reader = new FileReader();

      reader.onload = (e) => {
        const result = e.target?.result;
        const workbook = xlsx.read(result, { type: 'binary' });

        let data: {
          key: string;
          describe: string;
          apiStr: string;
        }[] = [];

        for (let sheet in workbook.Sheets) {
          data = xlsx.utils.sheet_to_json(workbook.Sheets[sheet], {
            header: ['key', 'describe', 'apiStr'],
            range: 1,
          });

          break;
        }

        const output = data.map(({ key, describe, apiStr }) => {
          const apiStrs = apiStr?.trim() ? apiStr.split(',') : [];

          const apis = apiStrs.map((apiStr) => {
            const [method, path] = apiStr.split(/\s/);

            return {
              method: method as Auth.Method,
              path,
            };
          });

          return {
            key: key?.trim(),
            describe: describe?.trim(),
            apis,
          };
        });

        setPermissions(output);
      };

      reader.readAsBinaryString(file);
    } catch (e) {
      console.log(e);
    }
  };

  const handleUplaod = () => {
    permissions.forEach((permission) => {
      create(platform, permission);
    });
  };

  const handleDownload = () => {
    const workbook = xlsx.utils.book_new();
    const sheet = xlsx.utils.aoa_to_sheet([
      ['key', 'describe', 'api'],
      ['aaa', 'bbb', 'GET /aaaa,POST /bbbb'],
    ]);
    xlsx.utils.book_append_sheet(workbook, sheet);

    const wopts = {
      bookType: 'xlsx', // 要生成的文件类型
      bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
      type: 'binary',
    } as any;
    const wbout = xlsx.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    openDownloadDialog(blob, 'template.xlsx');
  };

  return (
    <div className={styles.container}>
      <Space className={styles.buttons} size={16}>
        <Upload
          beforeUpload={(file) => {
            parseFile(file);
            return false;
          }}
          showUploadList={false}
        >
          <Button className={styles.button}>
            {formatMessage({
              id: 'Select File',
              defaultMessage: 'Select File',
            })}
          </Button>
        </Upload>
        <Button onClick={handleDownload} className={styles.button}>
          {formatMessage({
            id: 'Download Template',
            defaultMessage: 'Download Template',
          })}
        </Button>
        <Button
          onClick={handleUplaod}
          className={styles.button}
          disabled={!permissions.length || loading}
          type="primary"
        >
          {formatMessage({
            id: 'Upload',
            defaultMessage: 'Upload',
          })}
        </Button>
      </Space>
      <div className={styles.content}>
        <Table
          dataSource={permissions}
          pagination={false}
          columns={[
            {
              title: formatMessage({ id: 'Status', defaultMessage: 'Status' }),
              dataIndex: 'key',
              render: (value, record, index) => {
                const status = fetches[record.key];

                if (status?.loading) {
                  return <LoadingOutlined />;
                }

                if (status?.error) {
                  return <CloseCircleFilled style={{ color: '#F52209' }} />;
                }

                if (status && !status.error) {
                  return <CheckCircleFilled style={{ color: '#108043' }} />;
                }

                return '-';
              },
            },
            {
              title: formatMessage({ id: 'Key', defaultMessage: 'Key' }),
              dataIndex: 'key',
              render: (value, record, index) => {
                const status = fetches[record.key];
                const error = status?.error as any;

                return (
                  <>
                    <Input
                      value={value}
                      onChange={(e) => {
                        const newArr = Array.from(permissions);

                        newArr[index] = {
                          ...record,
                          key: e.target.value,
                        };

                        setPermissions(newArr);
                      }}
                    />
                    {error && (
                      <div style={{ color: '#F52209', marginTop: 8 }}>
                        {error?.data?.message}
                      </div>
                    )}
                  </>
                );
              },
            },
            {
              title: formatMessage({
                id: 'Description',
                defaultMessage: 'Description',
              }),
              dataIndex: 'describe',
              render: (value, record, index) => {
                return (
                  <Input
                    value={value}
                    onChange={(e) => {
                      const newArr = Array.from(permissions);

                      newArr[index] = {
                        ...record,
                        describe: e.target.value,
                      };

                      setPermissions(newArr);
                    }}
                  />
                );
              },
            },
            {
              title: formatMessage({
                id: 'API List',
                defaultMessage: 'API List',
              }),
              dataIndex: 'apis',
              render: (value, record, index) => {
                return (
                  <APIList
                    value={value}
                    onChange={(apis) => {
                      const newArr = Array.from(permissions);

                      newArr[index] = {
                        ...record,
                        apis,
                      };

                      setPermissions(newArr);
                    }}
                  />
                );
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Import;
