import { FC, useState } from 'react';
import { Button, Input, AttachedSelect as Select } from 'iglooform';
import { Input as AntdInput } from 'antd';
import { message } from 'antd';
import { useIntl, history } from 'umi';
import { useRequest } from '@umijs/hooks';
import {
  getAccountDetail,
  updateAccount,
  createAccount,
  updateAccountRole,
} from '../../service';
import loginMethod from '@/utils/login-method';
import styles from './index.less';

interface Props {
  platform: string;
  roles: Auth.Role[];
  accounts: Auth.Account[];
  emailTemplates: Auth.EmailTemplate[];
  onFinish: Function;
}

const INPUT_EMAIL = 0;
const CREATE_ACCOUNT = 1;
const UPDATE_ROLE = 2;

const NewAccount: FC<Props> = ({
  platform,
  roles,
  accounts,
  emailTemplates,
  onFinish,
}) => {
  const [step, setStep] = useState(INPUT_EMAIL);
  const [email, setEmail] = useState<string>();
  const [accountDetail, setAccountDetail] = useState<Auth.EditAccount>(
    {} as Auth.EditAccount,
  );
  const { formatMessage } = useIntl();

  const { run: connectPlatform, loading: connectLoading } = useRequest(
    updateAccount,
    {
      manual: true,
      onSuccess: ({ data }) => {
        setStep(UPDATE_ROLE);
      },
      onError: (err: any) => {
        message.error(
          err?.data?.message ||
            'Connecting platform failed. Please try again later or contact System Administrator.',
        );
      },
    },
  );

  const { run: getDetail, loading: detailLoading } = useRequest(
    getAccountDetail,
    {
      manual: true,
      onSuccess: ({ data }) => {
        if (data?.length) {
          const detail = {
            ...data[0],
            platforms: [
              ...(data[0].platforms
                ? data[0].platforms.map(({ key }: any) => key)
                : []),
              platform,
            ],
          };

          const account = accounts.find(({ email }) => email === detail.email);

          if (account) {
            detail.role_ids = account?.roles?.map(({ id }) => id) || [];
          }

          setAccountDetail(detail);
          connectPlatform(detail);
          return;
        }

        setAccountDetail({
          email: email as string,
          platforms: [platform],
        });

        setStep(CREATE_ACCOUNT);
      },
      onError: (err: any) => {
        message.error(
          err?.data?.message ||
            'Getting account detail failed. Please try again later or contact System Administrator.',
        );
      },
    },
  );

  const { run: create, loading: createLoading } = useRequest(createAccount, {
    manual: true,
    onSuccess: ({ id }) => {
      setAccountDetail({
        ...accountDetail,
        uid: id,
      });
      setStep(UPDATE_ROLE);
    },
    onError: (err: any) => {
      message.error(
        err?.data?.message ||
          'Creating account failed. Please try again later or contact System Administrator.',
      );
    },
  });

  const { run: update, loading: updateLoading } = useRequest(
    updateAccountRole,
    {
      manual: true,
      onSuccess: () => {
        onFinish();
      },
      onError: (err: any) => {
        message.error(err?.data?.message);
      },
    },
  );

  let component = null;

  if (step === INPUT_EMAIL) {
    component = (
      <div className={styles.content}>
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({ id: 'Email', defaultMessage: 'Email' })}
          </div>
          <Input
            className={styles.input}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            type="primary"
            onClick={() => getDetail([email as string])}
            loading={detailLoading || connectLoading}
            disabled={!email}
          >
            {formatMessage({ id: 'Next', defaultMessage: 'Next' })}
          </Button>
        </div>
      </div>
    );
  }

  if (step === CREATE_ACCOUNT) {
    component = (
      <div className={styles.content}>
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({ id: 'Email', defaultMessage: 'Email' })}
          </div>
          <Input className={styles.input} value={email} disabled />
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({ id: 'Company', defaultMessage: 'Company' })}
          </div>
          <Input
            className={styles.input}
            value={accountDetail?.company_name}
            onChange={(e) =>
              setAccountDetail({
                ...accountDetail,
                company_name: e.target.value,
              })
            }
          />
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({
              id: 'Email Template',
              defaultMessage: 'Email Template',
            })}
          </div>
          <Select
            className={styles.input}
            value={accountDetail?.mail_template}
            onChange={(v) =>
              setAccountDetail({
                ...accountDetail,
                mail_template: v as string,
              })
            }
            options={emailTemplates?.map((k) => ({ label: k, value: k }))}
          />
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({
              id: 'PhoneNumber',
              defaultMessage: 'PhoneNumber',
            })}
          </div>
          <AntdInput.Group compact className={styles.input}>
            <AntdInput
              style={{ width: '20%' }}
              onChange={(e) =>
                setAccountDetail({
                  ...accountDetail,
                  phone_area_code: e.target.value,
                })
              }
            />
            <AntdInput
              style={{ width: '80%' }}
              onChange={(e) =>
                setAccountDetail({
                  ...accountDetail,
                  phone_number: e.target.value,
                })
              }
            />
          </AntdInput.Group>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({
              id: 'Login Method',
              defaultMessage: 'Login Method',
            })}
          </div>
          <Select
            className={styles.input}
            value={loginMethod.filter(
              (method) =>
                !(accountDetail?.disabled_login_mehtods || []).includes(method),
            )}
            onChange={(data) =>
              setAccountDetail({
                ...accountDetail,
                disabled_login_mehtods: loginMethod.filter(
                  (method) => !((data as string[]) || []).includes(method),
                ),
              })
            }
            multiple
          >
            <Select.Option key="password" value="password">
              {formatMessage({ id: 'Password', defaultMessage: 'Password' })}
            </Select.Option>
            <Select.Option key="google_oauth" value="google_oauth">
              {formatMessage({
                id: 'Google OAuth',
                defaultMessage: 'Google OAuth',
              })}
            </Select.Option>
            <Select.Option key="otp" value="otp">
              {formatMessage({ id: 'Otp', defaultMessage: 'Otp' })}
            </Select.Option>
          </Select>
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            type="primary"
            onClick={() => create(accountDetail)}
            loading={createLoading}
          >
            {formatMessage({ id: 'Create', defaultMessage: 'Create' })}
          </Button>
        </div>
      </div>
    );
  }

  if (step === UPDATE_ROLE) {
    component = (
      <div className={styles.content}>
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({ id: 'Email', defaultMessage: 'Email' })}
          </div>
          <Input className={styles.input} value={email} disabled />
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({ id: 'Role', defaultMessage: 'Role' })}
          </div>
          <Select
            className={styles.input}
            value={accountDetail?.role_ids}
            onChange={(data) =>
              setAccountDetail({
                ...accountDetail,
                role_ids: data as number[],
              })
            }
            multiple
            showSearch
          >
            {roles.map(({ id, name, key }) => (
              <Select.Option key={key} value={id as number}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            type="primary"
            onClick={() => update(platform, accountDetail)}
            loading={updateLoading}
          >
            {formatMessage({ id: 'Confirm', defaultMessage: 'Confirm' })}
          </Button>
        </div>
      </div>
    );
  }

  return <div className={styles.container}>{component}</div>;
};

export default NewAccount;
