import { FC, useState } from 'react';
import { Space, message, Table, Input as AntdInput } from 'antd';
import { Button, Modal, Input, AttachedSelect } from 'iglooform';
import { useIntl, history } from 'umi';
import { useRequest } from '@umijs/hooks';
import { useRecoilValue } from 'recoil';
import { currentPlatformState } from '@/store/global';
import { EditOutlined, StopOutlined } from '@ant-design/icons';
import {
  getAccounts,
  createAccount,
  updateAccount,
  getPlatforms,
  disableAccount,
} from './service';
import Import from './components/import';

import styles from './index.less';

const AdminAccount: FC = () => {
  const [accounts, setAccounts] = useState<Auth.Account[]>([]);
  const [platforms, setPlatforms] = useState<Auth.Platform[]>([]);
  const { formatMessage } = useIntl();
  const currentPlatform = useRecoilValue(currentPlatformState);
  const [showModal, setShowModal] = useState(false);
  const [editAccount, setEditAccount] = useState<Auth.Account>(
    {} as Auth.Account,
  );
  const [showUpload, setShowUplaod] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [searchKey, setSearchKey] = useState<any>();

  const { run: refresh, loading } = useRequest(getAccounts, {
    defaultParams: [page, pageSize],
    onSuccess: ({ data, total }) => {
      setAccounts(data);
      setTotal(total);
    },
  });

  useRequest(getPlatforms, {
    onSuccess: ({ platforms }) => {
      setPlatforms(platforms);
    },
  });

  const { run: create, loading: createLoading } = useRequest(createAccount, {
    manual: true,
    onSuccess: () => {
      refresh(page, pageSize);
      setShowModal(false);
    },
    onError: (err: any) => {
      message.error(err?.data?.message);
    },
  });

  const { run: update, loading: updateLoading } = useRequest(updateAccount, {
    manual: true,
    onSuccess: () => {
      refresh(page, pageSize);
      setShowModal(false);
    },
    onError: (err: any) => {
      message.error(err?.data?.message);
    },
  });

  const { run: disable } = useRequest(disableAccount, {
    manual: true,
    onSuccess: () => {
      refresh(page, pageSize, searchKey);
    },
    onError: (err: any) => {
      message.error(err?.data?.message);
    },
  });

  if (currentPlatform !== 'admin') {
    history.push('/platforms');
    return null;
  }

  const openCreateModal = () => {
    setEditAccount({} as Auth.Account);
    setShowModal(true);
  };

  const handleCreate = () => {
    if (editAccount.uid) {
      update(editAccount);
      return;
    }

    create(editAccount);
  };

  const handlePageChange = (newPage: number, newPageSize?: number) => {
    if (page !== newPage || pageSize !== newPageSize) {
      setPage(newPage);
      setPageSize(newPageSize || 10);
      refresh(newPage, newPageSize || 10);
    }
  };

  return (
    <div className={styles.container}>
      <Space className={styles.buttons} size={16}>
        <Button type="default" onClick={openCreateModal}>
          {formatMessage({ id: 'New Account', defaultMessage: 'New Account' })}
        </Button>
        <Button type="default" onClick={() => setShowUplaod(true)}>
          {formatMessage({
            id: 'Import Account',
            defaultMessage: 'Import Account',
          })}
        </Button>
      </Space>
      <Table
        className={styles.table}
        loading={loading}
        dataSource={accounts}
        columns={[
          {
            title: formatMessage({ id: 'Email', defaultMessage: 'Email' }),
            dataIndex: 'email',
            filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) => {
              return (
                <Space direction="vertical" size={16} style={{ padding: 24 }}>
                  <Input
                    onChange={(e) => setSelectedKeys([e.target.value])}
                    value={selectedKeys[0]}
                  />
                  <Space size={16}>
                    <Button
                      onClick={() => {
                        setSelectedKeys([]);
                        setSearchKey(undefined);
                        setPage(1);
                        refresh(1, pageSize);
                      }}
                      type="text"
                      size="small"
                    >
                      Reset
                    </Button>
                    <Button
                      onClick={() => {
                        setSearchKey(selectedKeys[0]);
                        setPage(1);
                        refresh(1, pageSize, selectedKeys[0] as string);
                      }}
                      type="text"
                      size="small"
                    >
                      Confirm
                    </Button>
                  </Space>
                </Space>
              );
            },
            render: (email, record) => {
              return (
                <span
                  style={
                    record.state === 'disable'
                      ? { color: '#f52209', textDecoration: 'line-through' }
                      : undefined
                  }
                >
                  {email}
                </span>
              );
            },
          },
          {
            title: formatMessage({ id: 'Company', defaultMessage: 'Company' }),
            dataIndex: 'company_name',
            render: (v) => v || '-',
          },
          {
            title: formatMessage({
              id: 'Phone Number',
              defaultMessage: 'Phone Number',
            }),
            dataIndex: 'phone_number',
            render: (v, record) => {
              const { phone_number, phone_area_code } = record;

              return phone_area_code && phone_number
                ? `${phone_area_code}${phone_number}`
                : '-';
            },
          },
          {
            title: formatMessage({
              id: 'Platform',
              defaultMessage: 'Platform',
            }),
            dataIndex: 'platforms',
            render: (platforms) =>
              (platforms || []).map(({ name }: any) => name).join(', ') || '-',
          },
          {
            title: formatMessage({
              id: 'Operation',
              defaultMessage: 'Operation',
            }),
            width: '20%',
            render: (_, record) =>
              record.email === 'admin@iglooinsure.com' ? (
                '-'
              ) : (
                <>
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => {
                      setEditAccount({
                        ...record,
                        platforms: (record?.platforms || []).map(
                          ({ key }: any) => key,
                        ),
                      });
                      setShowModal(true);
                    }}
                    type="link"
                  />
                  <Button
                    icon={<StopOutlined />}
                    onClick={() => {
                      Modal.confirm({
                        title: 'Confirm',
                        content: 'Do you confirm to disable the account?',
                        onOk: () => disable(record.uid as number),
                      });
                    }}
                    style={{ marginLeft: 16 }}
                    type="link"
                    disabled={record.state === 'disable'}
                  />
                </>
              ),
          },
        ]}
        rowKey="email"
        pagination={{
          current: page,
          pageSize,
          total,
          onChange: handlePageChange,
        }}
      />
      <Modal
        visible={showUpload}
        width="80vw"
        footer={null}
        title={formatMessage({
          id: 'Import Account',
          defaultMessage: 'Import Account',
        })}
        destroyOnClose
        onCancel={() => {
          refresh(page, pageSize);
          setShowUplaod(false);
        }}
        maskClosable={false}
        forceRender={false}
        limitHeight={false}
      >
        <Import platforms={platforms} accounts={accounts} />
      </Modal>
      <Modal
        className={styles.modal}
        width={600}
        forceRender={false}
        maskClosable={false}
        visible={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
        title={formatMessage({
          id: 'New Account',
          defaultMessage: 'New Account',
        })}
        destroyOnClose
        limitHeight={false}
      >
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({ id: 'Email', defaultMessage: 'Email' })}
          </div>
          <Input
            className={styles.input}
            value={editAccount?.email}
            onChange={(e) =>
              setEditAccount({
                ...editAccount,
                email: e.target.value,
              })
            }
          />
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({ id: 'Password', defaultMessage: 'Password' })}
          </div>
          <Input
            className={styles.input}
            value={editAccount?.password}
            onChange={(e) =>
              setEditAccount({
                ...editAccount,
                password: e.target.value,
              })
            }
          />
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({ id: 'Company', defaultMessage: 'Company' })}
          </div>
          <Input
            className={styles.input}
            value={editAccount?.company_name}
            onChange={(e) =>
              setEditAccount({
                ...editAccount,
                company_name: e.target.value,
              })
            }
          />
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({
              id: 'Phone Number',
              defaultMessage: 'Phone Number',
            })}
          </div>

          <AntdInput.Group compact className={styles.input}>
            <AntdInput
              style={{ width: '20%' }}
              onChange={(e) =>
                setEditAccount({
                  ...editAccount,
                  phone_area_code: e.target.value,
                })
              }
              value={editAccount.phone_area_code}
            />
            <AntdInput
              style={{ width: '80%' }}
              onChange={(e) =>
                setEditAccount({
                  ...editAccount,
                  phone_number: e.target.value,
                })
              }
              value={editAccount.phone_number}
            />
          </AntdInput.Group>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({ id: 'platforms', defaultMessage: 'platforms' })}
          </div>
          <AttachedSelect
            className={styles.input}
            value={editAccount?.platforms}
            onChange={(data) =>
              setEditAccount({
                ...editAccount,
                platforms: data as string[],
              })
            }
            multiple
            showSearch
          >
            {platforms.map(({ key, name }) => (
              <AttachedSelect.Option key={key} value={key}>
                {name}
              </AttachedSelect.Option>
            ))}
          </AttachedSelect>
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.saveButton}
            type="primary"
            onClick={handleCreate}
            loading={createLoading || updateLoading}
          >
            {formatMessage({ id: 'Confirm', defaultMessage: 'Confirm' })}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AdminAccount;
