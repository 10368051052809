import { FC, useEffect, useMemo } from 'react';
import { Layout, Menu, message, Popover } from 'antd';
import { ArrowDownOutlined } from 'iglooicon';
import { history, useIntl } from 'umi';
import { RouteProps } from 'react-router-dom';
import { useRecoilValue, RecoilRoot, useRecoilState } from 'recoil';
import {
  tokenState,
  currentPlatformState,
  platformsState,
  scopeState,
  emailState,
} from '@/store/global';
import { getMenus } from './menus';
import { useRequest } from '@umijs/hooks';
import { SwitchPlatform, Select } from 'iglooform';
import { getPermissions, getPlatforms } from '@/services/global';
import styles from './index.less';

const getDisplayPlatforms = (platforms: Auth.Platform[], scope: any) => {
  return platforms
    .filter(({ key }) => {
      if (key !== 'admin') {
        return (
          scope[`${key}_permission`] ||
          scope[`${key}_role`] ||
          scope[`${key}_account`]
        );
      }

      return scope.admin;
    })
    .sort((a: any) => {
      if (a?.key === 'admin') return -1;

      return 0;
    });
};

const BasicLayout: FC<RouteProps> = ({ location, children }) => {
  const [token, setToken] = useRecoilState(tokenState);
  const [platforms, setPlatforms] = useRecoilState(platformsState);
  const email = useRecoilValue(emailState);
  const [currentPlatform, setPlatform] = useRecoilState(currentPlatformState);
  const [scope, setScope] = useRecoilState(scopeState);

  const { formatMessage } = useIntl();
  const { run: getPlatform } = useRequest(getPlatforms, {
    onSuccess: (data) => {
      setPlatforms(getDisplayPlatforms(data.platforms, scope));
    },
    manual: true,
  });

  const { run } = useRequest(getPermissions, {
    manual: true,
    onSuccess: (data: any) => {
      const _scope = { loading: false } as any;

      data?.permissions?.forEach(({ key }: any) => {
        _scope[key] = true;
      });

      setScope(_scope);

      getPlatform();
    },
    onError: () => {
      message.error('Fetching permissions failed.');

      setScope({
        loading: false,
      });
    },
  });

  useEffect(() => {
    token && run();
  }, [token]);

  const menus = useMemo(
    () => getMenus(currentPlatform as string, scope),
    [currentPlatform, scope],
  );

  const showSider = [
    '/permission',
    '/role',
    '/account',
    '/admin-account',
    '/admin-platform',
    '/email-template',
    '/logos',
  ].includes(location?.pathname as string);

  const showHeader = location?.pathname !== '/login';

  if (!token && location?.pathname !== '/') {
    window.location.href = `${SSO_LOGIN}/login?app=${window.location.origin}`;
    return null;
  }

  if (!scope.loading && showSider && !menus.length) {
    message.error(`You don't have the access for platform ${currentPlatform}`);
    history.push('/platforms');
    return null;
  }

  const clickMenu = ({ key }: any) => {
    history.push(key);
  };

  const changePlatform = (platform: string) => {
    setPlatform(platform);
  };

  return (
    <Layout className={styles.layout}>
      {showHeader && (
        <Layout.Header className={styles.header}>
          <div className={styles.headerContent}>
            <div className={styles.leftBox}>
              <div
                className={styles.logo}
                onClick={() => {
                  history.push('/platforms');
                }}
              >
                Auth Center
              </div>
              {showSider && (
                <SwitchPlatform
                  platforms={platforms.map(({ key }) => key)}
                  currentPlatform={currentPlatform as string}
                  onChange={changePlatform}
                />
              )}
            </div>
            <div className={styles.rightBox}>
              <Select value="en-US" className={styles.selectLang} size="small">
                <Select.Option key="en-US" value="en-US">
                  En
                </Select.Option>
              </Select>
              <span className={styles.email}>{email}</span>
              <Popover
                content={
                  <div className={styles.popContent}>
                    <div
                      className={styles.opItem}
                      onClick={() => {
                        localStorage.removeItem('token');
                        window.location.href = `${SSO_LOGIN}/logout?app=${window.location.origin}`;
                      }}
                    >
                      {formatMessage({
                        id: 'Log Out',
                        defaultMessage: 'Log Out',
                      })}
                    </div>
                  </div>
                }
                trigger="click"
                placement="bottom"
                getPopupContainer={(trigger) =>
                  trigger.parentElement || document.body
                }
              >
                <div className={styles.operator}>
                  <ArrowDownOutlined style={{ fontSize: 24 }} />
                </div>
              </Popover>
            </div>
          </div>
        </Layout.Header>
      )}
      <Layout style={{ overflow: 'hidden' }}>
        {showSider && (
          <Layout.Sider className={styles.sider} width={212}>
            <Menu
              mode="inline"
              className={styles.menu}
              selectedKeys={location?.pathname ? [location?.pathname] : []}
              onClick={clickMenu}
            >
              {menus.map(({ title, key }) => (
                <Menu.Item key={key}>{title}</Menu.Item>
              ))}
            </Menu>
          </Layout.Sider>
        )}
        <Layout.Content className={styles.content} key={currentPlatform}>
          {children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default (props: any) => (
  <RecoilRoot>
    <BasicLayout {...props} />
  </RecoilRoot>
);
