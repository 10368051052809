import request from '@/utils/request';

export const getAccounts = (page: number, pageSize: number, email?: string) => {
  return request('/v1/admin_account/admin/accounts', {
    params: {
      page,
      size: pageSize,
      email,
    },
  });
};

export const createAccount = (account: Auth.Account) => {
  return request('/v1/admin_account/admin/accounts', {
    method: 'post',
    data: account,
  });
};

export const updateAccount = (account: Auth.Account) => {
  return request(`/v1/admin_account/admin/accounts/${account.uid}`, {
    method: 'put',
    data: account,
  });
};

export const getPlatforms = () => {
  return request('/v1/admin_account/admin/platforms');
};

export const disableAccount = (account_id: number) => {
  return request('/v1/admin_account/admin/accounts/disable', {
    method: 'post',
    data: {
      account_id,
    }
  });
}