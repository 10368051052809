import request from '@/utils/request';

export const getEmailTemplates = (platform: string) => {
  return request(
    `/v1/admin_account/admin/platform/${platform}/mail_templates`,
    {
      params: {
        type: 'register',
      },
    },
  );
};

export const updateEmailTemplates = (platform: string, templates: string[]) => {
  return request(
    `/v1/admin_account/admin/platform/${platform}/mail_templates`,
    {
      method: 'put',
      data: {
        templates,
      },
      params: {
        type: 'register',
      },
    },
  );
};
