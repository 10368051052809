// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from '/home/gitlab-runner/builds/y3bh9Xns/0/axinan/fe/auth-center/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": require('@/layouts/index.tsx').default,
    "routes": [
      {
        "path": "/account",
        "exact": true,
        "component": require('@/pages/account/index.tsx').default
      },
      {
        "path": "/admin-account",
        "exact": true,
        "component": require('@/pages/admin-account/index.tsx').default
      },
      {
        "path": "/admin-platform",
        "exact": true,
        "component": require('@/pages/admin-platform/index.tsx').default
      },
      {
        "path": "/email-template",
        "exact": true,
        "component": require('@/pages/email-template/index.tsx').default
      },
      {
        "path": "/",
        "exact": true,
        "component": require('@/pages/index.tsx').default
      },
      {
        "path": "/logos",
        "exact": true,
        "component": require('@/pages/logos/index.tsx').default
      },
      {
        "path": "/permission",
        "exact": true,
        "component": require('@/pages/permission/index.tsx').default
      },
      {
        "path": "/platforms",
        "exact": true,
        "component": require('@/pages/platforms/index.tsx').default
      },
      {
        "path": "/role",
        "exact": true,
        "component": require('@/pages/role/index.tsx').default
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
