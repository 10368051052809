import request from '@/utils/request';

export const getAccounts = (platform: string) => {
  return request(`/v1/admin_account/admin/platform/${platform}/accounts`);
};

export const updateAccountRole = (
  platform: string,
  account: Auth.EditAccount,
) => {
  return request(`/v1/admin_account/admin/platform/${platform}/account_roles`, {
    method: 'put',
    data: {
      platform,
      account_id: account?.uid,
      role_ids: account?.role_ids,
    },
  });
};

export const getRoles = (platform: string) => {
  return request(`/v1/admin_account/admin/platform/${platform}/roles`);
};

export const getEmailTemplates = (platform: string) => {
  return request(
    `/v1/admin_account/admin/platform/${platform}/mail_templates`,
    {
      params: {
        type: 'register',
      },
    },
  );
};

export const getAccountDetail = (emails: string[]) => {
  return request('/v1/admin_account/admin/accounts/mget', {
    method: 'post',
    data: {
      emails,
    },
  });
};

export const createAccount = (account: Auth.Account) => {
  return request('/v1/admin_account/admin/accounts', {
    method: 'post',
    data: account,
  });
};

export const updateAccount = (account: Auth.Account) => {
  return request(`/v1/admin_account/admin/accounts/${account.uid}`, {
    method: 'put',
    data: account,
  });
};

export const getBackupCode = (platform: string, account: Auth.Account) => {
  return request(
    `/v1/admin_account/admin/platform/${platform}/accounts/${account.uid}/backup_code`,
  );
};

export const generateCodeByPhoneNumber = (platform: string, data: any) => {
  return request(
    `/v1/admin_account/admin/platform/${platform}/accounts/backup_code_by_phone`,
    {
      data,
      method: 'post',
    },
  );
};
